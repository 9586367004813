import React, { useEffect, useState } from 'react';
import { MapContainer, GeoJSON } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import axios from '../utils/axiosConfig';

// Comprehensive country code mappings
const COUNTRY_CODE_MAPPINGS = {
    // North America
    'USA': 'US',
    'United States': 'US',
    'Canada': 'CA',
    'Mexico': 'MX',

    // Europe
    'France': 'FR',
    'United Kingdom': 'GB',
    'Italy': 'IT',
    'Italia': 'IT',
    'Germany': 'DE',
    'Deutschland': 'DE',
    'Spain': 'ES',
    'España': 'ES',
    'Belgium': 'BE',
    'Netherlands': 'NL',
    'Portugal': 'PT',
    'Ireland': 'IE',
    'Greece': 'GR',
    'Austria': 'AT',
    'Switzerland': 'CH',
    'Sweden': 'SE',
    'Norway': 'NO',
    'Denmark': 'DK',
    'Finland': 'FI',
    'Poland': 'PL',
    'Romania': 'RO',
    'Bulgaria': 'BG',
    'Hungary': 'HU',
    'Czech Republic': 'CZ',
    'Slovakia': 'SK',
    'Croatia': 'HR',
    'Slovenia': 'SI',
    'Estonia': 'EE',
    'Latvia': 'LV',
    'Lithuania': 'LT',

    // Asia
    'China': 'CN',
    'Japan': 'JP',
    'South Korea': 'KR',
    'Korea': 'KR',
    'India': 'IN',
    'Russia': 'RU',
    'Indonesia': 'ID',
    'Vietnam': 'VN',
    'Thailand': 'TH',
    'Malaysia': 'MY',
    'Singapore': 'SG',
    'Philippines': 'PH',

    // Oceania
    'Australia': 'AU',
    'New Zealand': 'NZ',

    // South America
    'Brazil': 'BR',
    'Argentina': 'AR',
    'Chile': 'CL',
    'Colombia': 'CO',
    'Peru': 'PE',
    'Venezuela': 'VE',

    // Africa
    'South Africa': 'ZA',
    'Egypt': 'EG',
    'Morocco': 'MA',
    'Nigeria': 'NG',
    'Kenya': 'KE',
    'Ethiopia': 'ET',
    'Ghana': 'GH',
    'Tanzania': 'TZ',

    // Middle East
    'Saudi Arabia': 'SA',
    'United Arab Emirates': 'AE',
    'Israel': 'IL',
    'Turkey': 'TR',
    'Iran': 'IR',
    'Iraq': 'IQ'
};

// Define a palette of distinct colors for options
const COLOR_PALETTE = [
    [46, 204, 113],   // Green
    [255, 99, 132],   // Red
    [255, 206, 86],   // Yellow
    [153, 102, 255],  // Purple
    [54, 162, 235],   // Blue
    [255, 159, 64],   // Orange
    [75, 192, 192],   // Turquoise
    [233, 30, 99],    // Pink
    [148, 159, 177],  // Gray blue
    [241, 90, 34],    // Bright orange
    [0, 168, 133],    // Teal
    [121, 80, 242],   // Violet
    [94, 92, 230]     // Indigo
];

// Define region centers
const REGION_CENTERS = {
    'EU': [48.5, 10],     // Europe
    'NA': [39.8, -98.5],  // North America
    'AS': [34.0, 100.0],  // Asia
    'default': [20, 0]    // Default center (Africa/Middle East)
};

// Map country codes to regions
const COUNTRY_REGIONS = {
    'US': 'NA', 'CA': 'NA', 'MX': 'NA',  // North America
    'FR': 'EU', 'DE': 'EU', 'IT': 'EU', 'GB': 'EU', 'ES': 'EU',  // Europe
    'CN': 'AS', 'JP': 'AS', 'KR': 'AS', 'IN': 'AS',  // Asia
    // Add more as needed
};

const PollMap = ({ pollId }) => {
    const [geoData, setGeoData] = useState(null);
    const [countryData, setCountryData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [mapCenter, setMapCenter] = useState(REGION_CENTERS.default);
    const [optionColors, setOptionColors] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [geoResponse, statsResponse] = await Promise.all([
                    fetch('https://raw.githubusercontent.com/holtzy/D3-graph-gallery/master/DATA/world.geojson'),
                    axios.get(`/api/polls/${pollId}/geo-stats`)
                ]);
                
                const geoJson = await geoResponse.json();
                const stats = statsResponse.data;

                // Get all unique options from the country data
                const allOptions = new Set();
                Object.values(stats.countries).forEach(country => {
                    Object.keys(country.optionVotes).forEach(option => {
                        allOptions.add(option);
                    });
                });

                // Create a mapping of options to colors
                const colors = {};
                Array.from(allOptions).forEach((option, index) => {
                    colors[option] = COLOR_PALETTE[index % COLOR_PALETTE.length];
                });

                setOptionColors(colors);
                setGeoData(geoJson);
                setCountryData(stats.countries);

                // Determine region based on countries with votes
                const countries = Object.keys(stats.countries);
                if (countries.length > 0) {
                    const firstCountry = countries[0];
                    const region = COUNTRY_REGIONS[firstCountry] || 'default';
                    setMapCenter(REGION_CENTERS[region]);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [pollId]);

    const getCountryCode = (feature) => {
        if (!feature || !feature.properties) {
            return null;
        }

        const props = feature.properties;
        const name = props.name;
        
        // Try direct mapping first
        if (COUNTRY_CODE_MAPPINGS[name]) {
            return COUNTRY_CODE_MAPPINGS[name];
        }

        // Try various property names for the country code
        const code = props.iso_a2 || 
                    props.ISO_A2 || 
                    props.iso_a3 || 
                    props.ISO_A3 || 
                    props.ADM0_A3;

        if (code) {
            // Normalize to 2-letter code
            const normalizedCode = code.length > 2 ? code.slice(0, 2) : code;
            return normalizedCode.toUpperCase();
        }

        // If we still don't have a code, try to extract it from the properties
        if (props.ISO || props.iso) {
            return (props.ISO || props.iso).toUpperCase();
        }

        return null;
    };

    const getColor = (feature) => {
        const countryCode = getCountryCode(feature);
        if (!countryCode || !countryData) {
            return '#2d2d2d';
        }

        const country = countryData[countryCode];
        if (!country || !country.optionVotes) {
            return '#2d2d2d';
        }

        const votes = country.optionVotes;
        if (Object.keys(votes).length === 0) {
            return '#2d2d2d';
        }

        const totalVotes = Object.values(votes).reduce((a, b) => a + b, 0);
        if (totalVotes === 0) {
            return '#2d2d2d';
        }

        // Sort options by votes to get top results
        const sortedOptions = Object.entries(votes)
            .sort(([,a], [,b]) => b - a);

        const [dominantOption, maxVotes] = sortedOptions[0];
        
        // Get the color for this option
        const baseColor = optionColors[dominantOption] || [128, 128, 128];

        // Calculate percentage for intensity
        const percentage = maxVotes / totalVotes;
        const intensity = 0.3 + (percentage * 0.7); // 30% to 100% intensity

        // Mix with background color based on intensity
        const adjustedColor = baseColor.map(channel => 
            Math.round((channel * intensity) + (45 * (1 - intensity)))
        );

        return `rgb(${adjustedColor.join(',')})`;
    };

    const style = (feature) => {
        return {
            fillColor: getColor(feature),
            weight: 1,
            opacity: 1,
            color: '#444',
            dashArray: '',
            fillOpacity: 0.9  // Keep full opacity, we're controlling intensity through the color
        };
    };

    const onEachFeature = (feature, layer) => {
        const countryCode = getCountryCode(feature);
        const country = countryData?.[countryCode];

        if (country) {
            const votes = country.optionVotes;
            const totalVotes = Object.values(votes).reduce((a, b) => a + b, 0);
            
            // Get top result
            const [topOption, topVotes] = Object.entries(votes)
                .sort(([,a], [,b]) => b - a)[0];

            const percentage = Math.round((topVotes / totalVotes) * 100);

            layer.bindPopup(`
                <strong>${feature.properties.name}</strong> ${topOption}: ${percentage}%
            `);
        }
    };

    if (loading) return <div>Loading map data...</div>;

    // Debug the country data
    console.log('Available country codes:', countryData ? Object.keys(countryData) : 'No country data');

    return (
        <div className="poll-map">
            <MapContainer
                center={mapCenter}
                zoom={2}
                style={{ height: '400px', width: '100%', background: '#1a1a1a' }}
                minZoom={2}
                maxZoom={5}
                scrollWheelZoom={true}
                touchZoom={true}
                dragging={true}
                zoomControl={true}
                doubleClickZoom={true}
                boxZoom={true}
                keyboard={true}
                attributionControl={false}
            >
                {geoData && (
                    <GeoJSON
                        data={geoData}
                        style={style}
                        onEachFeature={onEachFeature}
                    />
                )}
            </MapContainer>
        </div>
    );
};

export default PollMap; 