import React, { useState, useEffect } from 'react';
import { Routes, Route, Link, Navigate } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import Home from './components/Home';
import New from './components/New';
import CreatePoll from './components/CreatePoll';
import PollPage from './components/PollPage';
import SearchBar from './components/SearchBar';
import logo from './assets/opini.io_night.png';
import axios from './utils/axiosConfig';
import Admin from './components/admin/Admin';
import AdminLogin from './components/admin/AdminLogin';
import ProtectedRoute from './components/admin/ProtectedRoute';
import { PollProvider } from './context/PollContext';
import { useMediaQuery } from 'react-responsive';
import SearchResults from './components/SearchResults';
import PrivacyPolicy from './components/PrivacyPolicy';

const MainApp = () => {
    const [canCreate, setCanCreate] = useState(true);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const isMobile = useMediaQuery({ maxWidth: 768 });

    useEffect(() => {
        const checkPollLimit = async () => {
            try {
                const response = await axios.get('/api/check-poll-limit');
                setCanCreate(response.data.canCreate);
            } catch (error) {
                console.error('Error checking poll limit:', error);
                setCanCreate(false);
            }
        };
        checkPollLimit();
    }, []);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const toggleCategory = (category) => {
        setSelectedCategories(prev => 
            prev.includes(category) 
                ? prev.filter(c => c !== category)
                : [...prev, category]
        );
    };

    return (
        <PollProvider>
            <div className="App">
                <header className="app-header">
                    <button className={`burger-menu ${isSidebarOpen ? 'open' : ''}`} onClick={toggleSidebar}>
                        {isSidebarOpen ? '×' : '☰'}
                    </button>
                    <Link to="/" className="brand">
                        <img src={logo} alt="opini.io logo" className="logo" />
                        <span className="brand-name">opini.io</span>
                    </Link>
                    <div className="header-right">
                        <SearchBar isMobile={isMobile} />
                        <Link 
                            to={canCreate ? "/create-poll" : "#"} 
                            className={`create-button ${!canCreate ? 'disabled' : ''}`}
                            title={!canCreate ? 'Daily poll creation limit reached' : 'Create new poll'}
                            onClick={(e) => !canCreate && e.preventDefault()}
                        >
                            Create
                        </Link>
                    </div>
                </header>
                <div className={`sidebar-overlay ${isSidebarOpen ? 'open' : ''}`} onClick={() => setIsSidebarOpen(false)} />
                <div className={`sidebar ${isSidebarOpen ? 'open' : ''}`}>
                    <Sidebar 
                        isOpen={isSidebarOpen} 
                        onClose={() => setIsSidebarOpen(false)}
                        selectedCategories={selectedCategories}
                        onToggleCategory={toggleCategory}
                    />
                </div>
                <Routes>
                    <Route path="/" element={<Home selectedCategories={selectedCategories} />} />
                    <Route path="/new" element={<New selectedCategories={selectedCategories} />} />
                    <Route path="/create-poll" element={<CreatePoll />} />
                    <Route path="/poll/:pollId" element={<PollPage selectedCategories={selectedCategories} />} />
                    <Route path="/search" element={<SearchResults />} />
                    <Route path="/privacy" element={<PrivacyPolicy />} />
                    <Route path="/admin/*" element={<Navigate to="/admin/login" replace />} />
                    <Route path="/admin/login" element={<AdminLogin />} />
                    <Route 
                        path="/admin/dashboard" 
                        element={
                            <ProtectedRoute>
                                <Admin />
                            </ProtectedRoute>
                        } 
                    />
                </Routes>
            </div>
        </PollProvider>
    );
};

export default MainApp; 