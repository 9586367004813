import React, { useEffect, useState, useCallback } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MainApp from './MainApp';
import './App.css';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from './assets/opini.io_night.png';
import { AnimationProvider } from './context/AnimationContext';

const App = () => {
    const [isRateLimited, setIsRateLimited] = useState(false);
    const [retryTimestamp, setRetryTimestamp] = useState(null);
    const [timeLeft, setTimeLeft] = useState(0);
    const [isChecking, setIsChecking] = useState(false);

    const checkRateLimit = useCallback(async () => {
        if (isChecking) return;
        
        try {
            setIsChecking(true);
            const rateLimitInfo = localStorage.getItem('rateLimitInfo');
            
            if (rateLimitInfo) {
                const info = JSON.parse(rateLimitInfo);
                if (info.retryTimestamp > Date.now()) {
                    setIsRateLimited(true);
                    setRetryTimestamp(info.retryTimestamp);
                    return;
                } else {
                    localStorage.removeItem('rateLimitInfo');
                    setIsRateLimited(false);
                    setRetryTimestamp(null);
                }
            }
        } catch (error) {
            console.error('Error checking rate limit:', error);
        } finally {
            setIsChecking(false);
        }
    }, [isChecking]);

    // Update timer every second
    useEffect(() => {
        let timer;
        if (isRateLimited && retryTimestamp) {
            timer = setInterval(() => {
                const remaining = Math.ceil((retryTimestamp - Date.now()) / 1000);
                setTimeLeft(remaining);
                
                // Auto refresh when timer reaches 0
                if (remaining <= 0) {
                    localStorage.removeItem('rateLimitInfo');
                    window.location.reload();
                }
            }, 1000);
        }
        return () => timer && clearInterval(timer);
    }, [isRateLimited, retryTimestamp]);

    // Check rate limit status periodically
    useEffect(() => {
        checkRateLimit();
        const interval = isRateLimited ? setInterval(checkRateLimit, 5000) : null;
        return () => interval && clearInterval(interval);
    }, [isRateLimited, checkRateLimit]);

    // Format time left into minutes and seconds
    const formatTimeLeft = (seconds) => {
        if (seconds <= 0) return "0:00";
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
    };

    // Update the rate limit handling in App.js
    const handleRateLimit = (error) => {
        if (error.response?.status === 429) {
            const retryAfter = error.response.data.retry_after || 60;
            const retryTimestamp = Date.now() + (retryAfter * 1000);
            
            localStorage.setItem('rateLimitInfo', JSON.stringify({
                retryTimestamp,
                retryAfter
            }));
            
            setIsRateLimited(true);
            setRetryTimestamp(retryTimestamp);
        }
    };

    // Add this to your axios interceptors
    axios.interceptors.response.use(
        response => response,
        error => {
            if (error.response?.status === 429) {
                handleRateLimit(error);
            }
            return Promise.reject(error);
        }
    );

    // If rate limited, show rate limit page
    if (isRateLimited && retryTimestamp) {
        return (
            <div className="rate-limit-page">
                <div className="rate-limit-container">
                    <div className="logo">
                        <img src={logo} alt="opini.io logo" className="logo" />
                    </div>
                    <h1>Rate Limit Exceeded</h1>
                    <p>Please wait {formatTimeLeft(timeLeft)} before trying again.</p>
                    <div className="timer-bar">
                        <div 
                            className="timer-progress" 
                            style={{
                                width: `${(timeLeft / (retryTimestamp - Date.now()) * 1000) * 100}%`
                            }}
                        />
                    </div>
                    {timeLeft <= 0 && (
                        <button 
                            onClick={() => {
                                localStorage.removeItem('rateLimitInfo');
                                window.location.reload();
                            }}
                            className="retry-button"
                        >
                            Retry Now
                        </button>
                    )}
                </div>
            </div>
        );
    }

    return (
        <AnimationProvider>
            <Router>
                <Routes>
                    <Route path="/*" element={<MainApp />} />
                </Routes>
            </Router>
            <ToastContainer />
        </AnimationProvider>
    );
};

export default App;