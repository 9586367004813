import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { BiCategoryAlt } from 'react-icons/bi';
import { MdOutlineComputer, MdOutlinePeople, MdOutlineAttachMoney, 
    MdOutlineAccountBalance, MdOutlineScience, MdOutlineSports, 
    MdOutlineMood, MdOutlineHome, MdOutlineLibraryAdd, MdClose,
    MdFilterAltOff, MdOutlinePrivacyTip } from 'react-icons/md';
import axios from '../utils/axiosConfig';
import '../styles/Sidebar.css';

const Sidebar = ({ isOpen, selectedCategories, onToggleCategory }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const currentPath = location.pathname;

    const categories = [
        { name: 'Tech', icon: <MdOutlineComputer /> },
        { name: 'Society', icon: <MdOutlinePeople /> },
        { name: 'Economy', icon: <MdOutlineAttachMoney /> },
        { name: 'Politics', icon: <MdOutlineAccountBalance /> },
        { name: 'Science', icon: <MdOutlineScience /> },
        { name: 'Sports', icon: <MdOutlineSports /> },
        { name: 'Fun', icon: <MdOutlineMood /> }
    ];

    const handleNavigation = async (path) => {
        try {
            // Use 'new' instead of 'newest' to match the New component
            const type = path === '/' ? 'trending' : 'new';
            await axios.get('/api/polls/list', { 
                params: { type }
            });
            navigate(path);
        } catch (error) {
            if (error.response?.status === 429) {
                // Store rate limit info in localStorage
                const retryAfter = error.response.data.retry_after || 60;
                const retryTimestamp = Date.now() + (retryAfter * 1000);
                
                localStorage.setItem('rateLimitInfo', JSON.stringify({
                    retryTimestamp,
                    retryAfter
                }));
                
                // Navigate to root which will show the rate limit page
                window.location.reload();
            } else {
                console.error('Navigation error:', error);
            }
        }
    };

    const clearAllFilters = () => {
        selectedCategories.forEach(category => {
            onToggleCategory(category);
        });
    };

    return (
        <div className={`sidebar ${isOpen ? 'open' : ''}`}>
            <ul className="menu-list">
                <li>
                    <button 
                        className={`menu-link ${currentPath === '/' ? 'active' : ''}`}
                        onClick={() => handleNavigation('/')}
                    >
                        <MdOutlineHome className="menu-icon" />
                        <span>Home</span>
                    </button>
                </li>
                <li>
                    <button 
                        className={`menu-link ${currentPath === '/new' ? 'active' : ''}`}
                        onClick={() => handleNavigation('/new')}
                    >
                        <MdOutlineLibraryAdd className="menu-icon" />
                        <span>New</span>
                    </button>
                </li>
                <li className="menu-category">
                    <span className="category-header">
                        <div className="header-left">
                            <BiCategoryAlt className="menu-icon" />
                            Categories
                        </div>
                        {selectedCategories.length > 0 && (
                            <MdFilterAltOff
                                className={`clear-filters-icon ${selectedCategories.length > 0 ? 'active' : ''}`}
                                onClick={clearAllFilters}
                                title="Clear all filters"
                            />
                        )}
                    </span>
                    <ul className="submenu">
                        {categories.map(({ name, icon }) => (
                            <li key={name}>
                                <button
                                    type="button"
                                    className={`category-button ${selectedCategories.includes(name) ? 'active' : ''}`}
                                    onClick={() => onToggleCategory(name)}
                                >
                                    {icon}
                                    <span>{name}</span>
                                    {selectedCategories.includes(name) && (
                                        <MdClose 
                                            className="remove-filter"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                onToggleCategory(name);
                                            }}
                                        />
                                    )}
                                </button>
                            </li>
                        ))}
                    </ul>
                </li>
                <li className="menu-separator"></li>
                <li>
                    <button 
                        className={`menu-link ${currentPath === '/privacy' ? 'active' : ''}`}
                        onClick={() => navigate('/privacy')}
                    >
                        <MdOutlinePrivacyTip className="menu-icon" />
                        <span>Privacy</span>
                    </button>
                </li>
            </ul>
        </div>
    );
};

export default Sidebar;