import React, { createContext, useState, useContext } from 'react';

const PollContext = createContext();

export const PollProvider = ({ children }) => {
    const [newPollId, setNewPollId] = useState(null);

    const markPollAsNew = (pollId) => {
        setNewPollId(pollId);
        // Clear the new poll state after animation
        setTimeout(() => {
            setNewPollId(null);
        }, 2000);
    };

    return (
        <PollContext.Provider value={{ newPollId, markPollAsNew }}>
            {children}
        </PollContext.Provider>
    );
};

export const usePollContext = () => useContext(PollContext); 