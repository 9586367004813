import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../../utils/axiosConfig';
import PendingPoll from './PendingPoll';
import RecurringPolls from './RecurringPolls';
import '../../styles/Admin.css';

const Admin = () => {
    const [pendingPolls, setPendingPolls] = useState([]);
    const [reportedPolls, setReportedPolls] = useState([]);
    const [error, setError] = useState(null);
    const [activeTab, setActiveTab] = useState('pending');
    const navigate = useNavigate();

    // Check authentication first
    useEffect(() => {
        const verifyAuth = async () => {
            try {
                await axios.get('/api/admin/verify');
            } catch (err) {
                navigate('/admin/login');
            }
        };
        verifyAuth();
    }, [navigate]);

    const fetchPendingPolls = useCallback(async () => {
        try {
            const response = await axios.get('/api/admin/pending');
            setPendingPolls(response.data);
            setError(null);
        } catch (err) {
            if (err.response?.status === 401) {
                navigate('/admin/login');
            } else {
                setError('Failed to fetch flagged polls');
            }
        }
    }, [navigate]);

    const fetchReportedPolls = useCallback(async () => {
        try {
            const response = await axios.get('/api/admin/reported');
            setReportedPolls(response.data);
            setError(null);
        } catch (err) {
            if (err.response?.status === 401) {
                navigate('/admin/login');
            } else {
                setError('Failed to fetch reported polls');
            }
        }
    }, [navigate]);

    // Fetch both types of polls on initial load
    useEffect(() => {
        if (activeTab === 'pending' || activeTab === 'reported') {
            Promise.all([
                fetchPendingPolls(),
                fetchReportedPolls()
            ]).catch(err => {
                setError('Failed to fetch polls');
            });
        }
    }, [fetchPendingPolls, fetchReportedPolls, activeTab]);

    const handleReview = async (pollId, action, notes, banIp) => {
        try {
            await axios.post(`/api/admin/review/${pollId}`, {
                action,
                notes,
                banIp
            });
            // Remove the reviewed poll from the list
            if (activeTab === 'pending') {
                setPendingPolls(prev => prev.filter(poll => poll.id !== pollId));
            } else {
                setReportedPolls(prev => prev.filter(poll => poll.id !== pollId));
            }
        } catch (err) {
            setError('Failed to submit review');
        }
    };

    return (
        <div className="admin-container">
            <div className="admin-header">
                <h1>Admin Dashboard</h1>
                <div className="admin-tabs">
                    <button 
                        className={`tab-button ${activeTab === 'pending' ? 'active' : ''}`}
                        onClick={() => setActiveTab('pending')}
                    >
                        Flagged ({pendingPolls.length})
                    </button>
                    <button 
                        className={`tab-button ${activeTab === 'reported' ? 'active' : ''}`}
                        onClick={() => setActiveTab('reported')}
                    >
                        Reported ({reportedPolls.length})
                    </button>
                    <button 
                        className={`tab-button ${activeTab === 'recurring' ? 'active' : ''}`}
                        onClick={() => setActiveTab('recurring')}
                    >
                        Recurring
                    </button>
                </div>
            </div>
            
            {error && <div className="admin-error">{error}</div>}
            
            {activeTab === 'recurring' ? (
                <RecurringPolls />
            ) : (
                <div className="pending-polls">
                    {activeTab === 'pending' ? (
                        pendingPolls.length === 0 ? (
                            <div className="no-pending">No polls flagged for review</div>
                        ) : (
                            pendingPolls.map(poll => (
                                <PendingPoll 
                                    key={poll.id}
                                    poll={poll}
                                    onReview={handleReview}
                                />
                            ))
                        )
                    ) : (
                        reportedPolls.length === 0 ? (
                            <div className="no-pending">No reported polls</div>
                        ) : (
                            reportedPolls.map(poll => (
                                <PendingPoll 
                                    key={poll.id}
                                    poll={poll}
                                    onReview={handleReview}
                                    isReported={true}
                                />
                            ))
                        )
                    )}
                </div>
            )}
        </div>
    );
};

export default Admin; 