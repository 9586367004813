import React, { useEffect, useState } from 'react';
import { usePolls } from '../hooks/usePolls';
import PollCard from './PollCard';
import { useMetaTags } from '../hooks/useMetaTags';
import { usePollContext } from '../context/PollContext';

const POLLS_PER_PAGE = 24;

const New = ({ selectedCategories }) => {
    const { polls, error, fetchPolls } = usePolls();
    const { newPollId } = usePollContext();
    const [visiblePolls, setVisiblePolls] = useState(POLLS_PER_PAGE);

    useMetaTags({
        title: 'Latest Polls - opini.io',
        description: 'Check out the newest polls on opini.io. Vote and see what others think!',
        url: `${window.location.origin}/new`,
        type: 'website'
    });

    useEffect(() => {
        fetchPolls('new', selectedCategories);
    }, [fetchPolls, selectedCategories]);

    const handleShowMore = () => {
        setVisiblePolls(prev => prev + POLLS_PER_PAGE);
    };

    const filteredPolls = polls.filter(poll => 
        selectedCategories.length === 0 || 
        selectedCategories.includes(poll.category)
    );

    return (
        <div className="container">
            <h1># Latest Polls</h1>
            {error && <div className="error">{error}</div>}
            <div className="polls">
                {filteredPolls.slice(0, visiblePolls).map(poll => (
                    <PollCard 
                        key={poll.id}
                        poll={poll}
                        isNew={poll.id === newPollId}
                    />
                ))}
            </div>
            {filteredPolls.length > visiblePolls && (
                <div className="button-container">
                    <button 
                        className="show-more-button"
                        onClick={handleShowMore}
                    >
                        Show More
                    </button>
                </div>
            )}
        </div>
    );
};

export default New;