import React from 'react';
import { formatDistanceToNow } from 'date-fns';

const TimeAgo = ({ date }) => {
    try {
        if (!date) return null;
        
        // Convert string date to Date object
        const dateObj = new Date(date);
        
        // Check if date is valid
        if (isNaN(dateObj.getTime())) {
            console.error('Invalid date value:', date);
            return null;
        }
        
        const timeAgo = formatDistanceToNow(dateObj, { addSuffix: true });
        return <span>{timeAgo}</span>;
    } catch (error) {
        console.error('Error formatting date:', error);
        return null;
    }
};

export default TimeAgo; 