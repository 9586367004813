import React, { useEffect, useState } from 'react';
import { usePolls } from '../hooks/usePolls';
import PollCard from './PollCard';
import { useMetaTags } from '../hooks/useMetaTags';
import { useLocation } from 'react-router-dom';

const POLLS_PER_PAGE = 12;

const Home = ({ selectedCategories }) => {
    const { polls: trendingPolls, fetchPolls: fetchTrending } = usePolls();
    const { polls: closedPolls, fetchPolls: fetchClosed } = usePolls();
    const location = useLocation();
    
    // Get timeFilter from URL or state or default to 'day'
    const getInitialTimeFilter = () => {
        const params = new URLSearchParams(window.location.search);
        const urlTimeFilter = params.get('timeFilter');
        return urlTimeFilter || location.state?.timeFilter || 'day';
    };

    const [timeFilter, setTimeFilter] = useState(getInitialTimeFilter());

    const isMobile = window.innerWidth <= 1024;
    const initialPollCount = isMobile ? 4 : POLLS_PER_PAGE;
    
    const [visibleTrendingPolls, setVisibleTrendingPolls] = useState(initialPollCount);
    const [visibleClosedPolls, setVisibleClosedPolls] = useState(initialPollCount);
    const [hasExpandedTrending, setHasExpandedTrending] = useState(false);
    const [hasExpandedClosed, setHasExpandedClosed] = useState(false);

    useEffect(() => {
        fetchTrending('trending', selectedCategories, timeFilter);
        fetchClosed('closed', selectedCategories, timeFilter);
    }, [fetchTrending, fetchClosed, selectedCategories, timeFilter]);

    useEffect(() => {
        const handleResize = () => {
            const isMobile = window.innerWidth <= 1024;
            const baseCount = isMobile ? 4 : POLLS_PER_PAGE;
            
            // Only reset to initial count if not expanded
            if (!hasExpandedTrending) {
                setVisibleTrendingPolls(baseCount);
            }
            if (!hasExpandedClosed) {
                setVisibleClosedPolls(baseCount);
            }
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [hasExpandedTrending, hasExpandedClosed]);

    const handleTimeFilterChange = (newFilter) => {
        setTimeFilter(newFilter);
        setHasExpandedTrending(false);
        setHasExpandedClosed(false);
        setVisibleTrendingPolls(initialPollCount);
        setVisibleClosedPolls(initialPollCount);
    };

    const handleShowMoreTrending = () => {
        setVisibleTrendingPolls(prev => prev + POLLS_PER_PAGE);
        setHasExpandedTrending(true);
    };

    const handleShowMoreClosed = () => {
        setVisibleClosedPolls(prev => prev + POLLS_PER_PAGE);
        setHasExpandedClosed(true);
    };

    // Filter polls based on categories
    const filteredTrendingPolls = trendingPolls.filter(poll => 
        !poll.is_closed && (selectedCategories.length === 0 || selectedCategories.includes(poll.category))
    );

    const filteredClosedPolls = closedPolls.filter(poll => 
        poll.is_closed && (selectedCategories.length === 0 || selectedCategories.includes(poll.category))
    );

    useMetaTags({
        title: 'opini.io - make your voice heard',
        description: 'Create and share polls instantly. Vote on trending topics and see what others think.',
        url: window.location.origin,
        type: 'website'
    });

    // Update URL when filter changes
    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        
        // Clear existing timeFilter if any
        params.delete('timeFilter');
        
        // Set new timeFilter
        if (timeFilter !== 'day') {  // Only add to URL if not default
            params.set('timeFilter', timeFilter);
        }
        
        const newUrl = params.toString() 
            ? `${window.location.pathname}?${params.toString()}`
            : window.location.pathname;
            
        window.history.replaceState(
            { timeFilter },
            '',
            newUrl
        );
    }, [timeFilter]);

    return (
        <div className="container">
            <div className="time-filters">
                <button 
                    className={`filter-button ${timeFilter === 'day' ? 'active' : ''}`}
                    onClick={() => handleTimeFilterChange('day')}
                >
                    Day
                </button>
                <button 
                    className={`filter-button ${timeFilter === 'week' ? 'active' : ''}`}
                    onClick={() => handleTimeFilterChange('week')}
                >
                    Week
                </button>
            </div>
            
            <div className="split-layout">
                <div className="polls-section trending">
                    <h1># Trending</h1>
                    <div className="polls polls-grid">
                        {filteredTrendingPolls.slice(0, visibleTrendingPolls).map(poll => (
                            <PollCard 
                                key={poll.id} 
                                poll={poll} 
                                selectedCategories={selectedCategories}
                            />
                        ))}
                    </div>
                    {filteredTrendingPolls.length > visibleTrendingPolls && (
                        <div className="button-container">
                            <button 
                                className="show-more-button"
                                onClick={handleShowMoreTrending}
                            >
                                Show More
                            </button>
                        </div>
                    )}
                </div>

                <div className="polls-divider"></div>

                <div className="polls-section closed">
                    <h1># Just closed</h1>
                    <div className="polls polls-grid">
                        {filteredClosedPolls.slice(0, visibleClosedPolls).map(poll => (
                            <PollCard 
                                key={poll.id} 
                                poll={poll}
                                selectedCategories={selectedCategories}
                            />
                        ))}
                    </div>
                    {filteredClosedPolls.length > visibleClosedPolls && (
                        <div className="button-container">
                            <button 
                                className="show-more-button"
                                onClick={handleShowMoreClosed}
                            >
                                Show More
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Home;