import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import axios from '../utils/axiosConfig';
import PollCard from './PollCard';

const SearchResults = () => {
    const [searchParams] = useSearchParams();
    const [results, setResults] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [visibleResults, setVisibleResults] = useState(10);

    useEffect(() => {
        const fetchResults = async () => {
            setLoading(true);
            setError(null);
            try {
                const query = searchParams.get('q');
                const category = searchParams.get('category');
                
                if (!query) {
                    setResults([]);
                    setLoading(false);
                    return;
                }

                const response = await axios.get('/api/search', {
                    params: {
                        q: query,
                        category,
                        limit: 50
                    }
                });

                setResults(response.data.results);
            } catch (err) {
                console.error('Search error:', err);
                setError('Failed to fetch search results');
            } finally {
                setLoading(false);
            }
        };

        fetchResults();
        setVisibleResults(10); // Reset visible results when search changes
    }, [searchParams]);

    const handleShowMore = () => {
        setVisibleResults(prev => Math.min(prev + 10, results.length));
    };

    if (loading) {
        return <div className="loading">Loading results...</div>;
    }

    if (error) {
        return <div className="error">{error}</div>;
    }

    if (results.length === 0) {
        return <div className="no-results">No results found</div>;
    }

    return (
        <div className="container">
            <div className="split-layout">
                <div className="polls-section">
                    <div className="polls-grid">
                        {results.slice(0, visibleResults).map(poll => (
                            <PollCard 
                                key={`result-${poll.id}`} 
                                poll={poll}
                            />
                        ))}
                    </div>
                    {visibleResults < results.length && (
                        <div className="button-container">
                            <button 
                                className="show-more-button"
                                onClick={handleShowMore}
                            >
                                Show More
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default SearchResults; 