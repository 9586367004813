import React, { useState, useRef, useEffect } from 'react';
import { IoSearchOutline, IoCalendarOutline } from 'react-icons/io5';
import { MdOutlineRepeat } from 'react-icons/md';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from '../utils/axiosConfig';
import TimeAgo from './TimeAgo';
import '../styles/SearchBar.css';

const SearchBar = ({ isMobile }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [results, setResults] = useState([]);
    const [isInputFocused, setIsInputFocused] = useState(false);
    const searchRef = useRef(null);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (searchRef.current && !searchRef.current.contains(event.target)) {
                setIsInputFocused(false);
                if (isMobile) {
                    setIsExpanded(false);
                    setSearchTerm('');
                    setResults([]);
                }
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [isMobile]);

    useEffect(() => {
        const debounceTimer = setTimeout(async () => {
            if (searchTerm.length >= 2) {
                try {
                    const response = await axios.get(`/api/search?q=${encodeURIComponent(searchTerm)}&limit=5`);
                    if (response.data && response.data.results) {
                        setResults(response.data.results);
                    } else {
                        setResults([]);
                    }
                } catch (error) {
                    console.error('Search error:', error);
                    setResults([]);
                }
            } else {
                setResults([]);
            }
        }, 300); // Reduced debounce time for better responsiveness

        return () => clearTimeout(debounceTimer);
    }, [searchTerm]);

    // Reset search when changing pages
    useEffect(() => {
        setSearchTerm('');
        setResults([]);
        setIsInputFocused(false);
        setIsExpanded(false);
    }, [location.pathname]);

    const handleSearchClick = () => {
        if (isMobile && !isExpanded) {
            setIsExpanded(true);
            setTimeout(() => {
                const input = searchRef.current?.querySelector('.search-input');
                if (input) {
                    input.focus();
                    input.select();
                }
            }, 100);
        } else if (searchTerm.trim()) {
            handleSearchSubmit();
        }
    };

    const handleSearchSubmit = (e) => {
        if (e) e.preventDefault();
        if (searchTerm.trim()) {
            navigate(`/search?q=${encodeURIComponent(searchTerm)}`);
            setIsInputFocused(false);
            setIsExpanded(false);
            setSearchTerm('');
            setResults([]);
        }
    };

    const handleResultClick = (pollId) => {
        navigate(`/poll/${pollId}`);
        setIsInputFocused(false);
        setIsExpanded(false);
        setSearchTerm('');
        setResults([]);
    };

    const handleInputFocus = () => {
        setIsInputFocused(true);
    };

    const handleInputChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleShowAll = () => {
        handleSearchSubmit();
    };

    return (
        <div 
            ref={searchRef}
            className={`search-bar-container ${isMobile ? 'mobile' : ''} ${isExpanded ? 'expanded' : ''}`}
        >
            <form 
                className="search-form"
                onSubmit={handleSearchSubmit}
            >
                <button 
                    type="submit"
                    className="search-button"
                    onClick={handleSearchClick}
                >
                    <IoSearchOutline />
                </button>
                <input
                    type="text"
                    className="search-input"
                    placeholder="Search for opinions"
                    value={searchTerm}
                    onChange={handleInputChange}
                    onFocus={handleInputFocus}
                />
            </form>

            <div className={`search-results ${isInputFocused && searchTerm.length >= 2 ? 'visible' : ''}`}>
                {results && results.length > 0 && (
                    <>
                        {results.map((result, index) => (
                            <div
                                key={`search-result-${result.id}-${index}`}
                                className="search-result-item"
                                onClick={() => handleResultClick(result.id)}
                            >
                                <div className="result-title">{result.title}</div>
                                <div className="result-meta">
                                    <span className="result-category">{result.category}</span>
                                    <div className="result-time-container">
                                        {result.created_at && (
                                            <span className="result-time">
                                                {result.is_recurring && <span className="time-prefix">Last: </span>}
                                                <TimeAgo date={result.created_at} />
                                            </span>
                                        )}
                                        {result.is_recurring && (
                                            <IoCalendarOutline className="recurring-icon" title="Recurring Poll" />
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </>
                )}
                {searchTerm.trim() && (
                    <div className="search-result-item show-all" onClick={handleShowAll}>
                        <div className="show-all-text">
                            Search for "{searchTerm}" <IoSearchOutline />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default SearchBar; 