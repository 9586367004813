import { useEffect } from 'react';
import logo from '../assets/opini.io_night.png';

export function useMetaTags({ title, description, url, image, type = 'website' }) {
    useEffect(() => {
        // Default values
        const defaultTitle = 'opini.io - make your voice heard';
        const defaultDescription = 'Create and share polls instantly';
        const defaultImage = logo;

        // Format poll title and description without modifying the original values
        const formattedTitle = type === 'poll' 
            ? `Give your opinion! - ${title}`
            : title || defaultTitle;

        const formattedDescription = type === 'poll'
            ? description  // Use the description as is, without adding "Vote now:" prefix
            : description || defaultDescription;

        // Update document title
        document.title = formattedTitle;

        // All meta tags to update
        const metaTags = {
            // Open Graph
            'og:title': formattedTitle,
            'og:description': formattedDescription,
            'og:url': url || window.location.origin,
            'og:image': image || defaultImage,
            'og:type': type === 'poll' ? 'article' : 'website',
            'og:site_name': 'opini.io',
            
            // Twitter
            'twitter:card': 'summary_large_image',
            'twitter:site': '@opini_io',
            'twitter:title': formattedTitle,
            'twitter:description': formattedDescription,
            'twitter:image': image || defaultImage,
            
            // Standard meta
            'description': formattedDescription
        };
        
        // Update all meta tags
        Object.entries(metaTags).forEach(([property, content]) => {
            let meta = document.querySelector(`meta[property="${property}"]`) || 
                      document.querySelector(`meta[name="${property}"]`);
            
            if (!meta) {
                meta = document.createElement('meta');
                const attributeType = property.startsWith('twitter:') || property === 'description' 
                    ? 'name' 
                    : 'property';
                meta.setAttribute(attributeType, property);
                document.head.appendChild(meta);
            }
            
            meta.setAttribute('content', content);
        });
        
        // Cleanup function
        return () => {
            if (type === 'poll') {
                document.title = defaultTitle;
                Object.keys(metaTags).forEach(property => {
                    const meta = document.querySelector(`meta[property="${property}"]`) || 
                               document.querySelector(`meta[name="${property}"]`);
                    if (meta) {
                        const defaultContent = property.includes('image') ? defaultImage :
                                            property.includes('title') ? defaultTitle :
                                            property.includes('description') ? defaultDescription :
                                            property.includes('url') ? window.location.origin :
                                            '';
                        meta.setAttribute('content', defaultContent);
                    }
                });
            }
        };
    }, [title, description, url, image, type]);
} 