import React, { useState, useEffect } from 'react';
import axios from '../../utils/axiosConfig';
import { IoCalendarOutline } from 'react-icons/io5';
import '../../styles/Admin.css';

const RecurringPolls = () => {
    const [polls, setPolls] = useState([]);
    const [error, setError] = useState(null);
    const [showCreateForm, setShowCreateForm] = useState(false);
    const [formData, setFormData] = useState({
        title: '',
        category: 'Tech',
        options: ['', ''],
        interval_value: 30,
        interval_unit: 'days' // New field for interval unit
    });

    useEffect(() => {
        fetchPolls();
    }, []);

    const fetchPolls = async () => {
        try {
            const response = await axios.get('/api/admin/recurring');
            setPolls(response.data);
            setError(null);
        } catch (err) {
            setError('Failed to fetch recurring polls');
        }
    };

    const handleCreatePoll = async (e) => {
        e.preventDefault();
        setError(null);
        
        try {
            // Filter out empty options and trim them
            const validOptions = formData.options.filter(opt => opt.trim());
            
            // Check for duplicate options (case-insensitive and trimmed)
            const normalizedOptions = validOptions.map(opt => opt.trim().toLowerCase());
            const uniqueOptions = new Set(normalizedOptions);
            if (uniqueOptions.size !== normalizedOptions.length) {
                setError('Duplicate options are not allowed');
                return;
            }
            
            // Convert interval to days for API
            let interval_days = formData.interval_value;
            if (formData.interval_unit === 'months') {
                interval_days = formData.interval_value * 30;
            } else if (formData.interval_unit === 'minutes') {
                interval_days = formData.interval_value / (24 * 60);
            }

            await axios.post('/api/admin/recurring', {
                title: formData.title,
                category: formData.category,
                options: validOptions,
                interval_days: interval_days
            });
            setShowCreateForm(false);
            setFormData({
                title: '',
                category: 'Tech',
                options: ['', ''],
                interval_value: 30,
                interval_unit: 'days'
            });
            fetchPolls();
        } catch (err) {
            if (err.response?.status === 400 && err.response.data.error === 'Duplicate options') {
                setError('Duplicate options are not allowed');
            } else {
                setError('Failed to create recurring poll');
            }
        }
    };

    const handleUpdatePoll = async (id, isActive) => {
        try {
            await axios.put(`/api/admin/recurring/${id}`, {
                is_active: isActive
            });
            fetchPolls();
        } catch (err) {
            setError('Failed to update recurring poll');
        }
    };

    const addOption = () => {
        if (formData.options.length < 6) {
            setFormData({
                ...formData,
                options: [...formData.options, '']
            });
        }
    };

    const removeOption = (index) => {
        if (formData.options.length > 2) {
            setFormData({
                ...formData,
                options: formData.options.filter((_, i) => i !== index)
            });
        }
    };

    return (
        <div className="recurring-polls-container">
            <div className="recurring-polls-header">
                <h2>Recurring Polls</h2>
                <button 
                    className="create-recurring-button"
                    onClick={() => setShowCreateForm(true)}
                >
                    Create Recurring Poll
                </button>
            </div>

            {error && <div className="admin-error">{error}</div>}

            {showCreateForm && (
                <div className="recurring-poll-form">
                    <h3>Create Recurring Poll</h3>
                    <form onSubmit={handleCreatePoll}>
                        <div className="form-group">
                            <label>Title</label>
                            <input
                                type="text"
                                value={formData.title}
                                onChange={(e) => setFormData({
                                    ...formData,
                                    title: e.target.value
                                })}
                                maxLength={90}
                                required
                            />
                        </div>

                        <div className="form-group">
                            <label>Category</label>
                            <select
                                value={formData.category}
                                onChange={(e) => setFormData({
                                    ...formData,
                                    category: e.target.value
                                })}
                            >
                                <option value="Tech">Tech</option>
                                <option value="Society">Society</option>
                                <option value="Economy">Economy</option>
                                <option value="Politics">Politics</option>
                                <option value="Science">Science</option>
                                <option value="Sports">Sports</option>
                                <option value="Fun">Fun</option>
                            </select>
                        </div>

                        <div className="form-group">
                            <label>Interval</label>
                            <div className="interval-inputs">
                                <input
                                    type="number"
                                    value={formData.interval_value}
                                    onChange={(e) => setFormData({
                                        ...formData,
                                        interval_value: parseInt(e.target.value)
                                    })}
                                    min={1}
                                    required
                                    style={{ width: '70%' }}
                                />
                                <select
                                    value={formData.interval_unit}
                                    onChange={(e) => setFormData({
                                        ...formData,
                                        interval_unit: e.target.value
                                    })}
                                    style={{ width: '30%' }}
                                >
                                    <option value="minutes">Minutes</option>
                                    <option value="days">Days</option>
                                    <option value="months">Months</option>
                                </select>
                            </div>
                        </div>

                        <div className="form-group">
                            <label>Options</label>
                            {formData.options.map((option, index) => (
                                <div key={index} className="option-input-group">
                                    <input
                                        type="text"
                                        value={option}
                                        onChange={(e) => {
                                            const newOptions = [...formData.options];
                                            newOptions[index] = e.target.value;
                                            setFormData({
                                                ...formData,
                                                options: newOptions
                                            });
                                        }}
                                        maxLength={45}
                                        placeholder={`Option ${index + 1}`}
                                        required
                                    />
                                    {formData.options.length > 2 && (
                                        <button
                                            type="button"
                                            onClick={() => removeOption(index)}
                                            className="remove-option"
                                        >
                                            ×
                                        </button>
                                    )}
                                </div>
                            ))}
                            {formData.options.length < 6 && (
                                <button
                                    type="button"
                                    onClick={addOption}
                                    className="add-option"
                                >
                                    + Add an option
                                </button>
                            )}
                        </div>

                        <div className="form-actions">
                            <button type="submit">Create</button>
                            <button 
                                type="button" 
                                onClick={() => setShowCreateForm(false)}
                            >
                                Cancel
                            </button>
                        </div>
                    </form>
                </div>
            )}

            <div className="recurring-polls-list">
                {polls.map(poll => (
                    <div key={poll.id} className="recurring-poll-item">
                        <div className="recurring-poll-info">
                            <div className="recurring-poll-title">
                                <IoCalendarOutline className="calendar-icon" />
                                <span>{poll.title}</span>
                            </div>
                            <div className="recurring-poll-details">
                                <span className="category">{poll.category}</span>
                                <span className="interval">
                                    {poll.interval_days < 1 
                                        ? `${Math.round(poll.interval_days * 24 * 60)} minutes`
                                        : poll.interval_days >= 30 
                                            ? `${Math.round(poll.interval_days / 30)} months`
                                            : `${poll.interval_days} days`}
                                </span>
                                <span className="instances">{poll.instances_count} instances</span>
                            </div>
                        </div>
                        <div className="recurring-poll-actions">
                            <button
                                onClick={() => handleUpdatePoll(poll.id, !poll.is_active)}
                                className={`toggle-button ${poll.is_active ? 'active' : ''}`}
                            >
                                {poll.is_active ? 'Active' : 'Inactive'}
                            </button>
                        </div>
                    </div>
                ))}
                {polls.length === 0 && (
                    <div className="no-recurring">No recurring polls found</div>
                )}
            </div>
        </div>
    );
};

export default RecurringPolls; 