import axios from 'axios';

const baseURL = process.env.NODE_ENV === 'development' 
    ? 'http://192.168.1.83:5001'  // Use new IP address
    : 'https://api.opini.io';  // Production URL

const instance = axios.create({
    baseURL,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    },
    timeout: 10000,
    maxRedirects: 5
});

// Store CSRF token
let csrfToken = null;

// Function to get CSRF token
const getCsrfToken = async () => {
    if (!csrfToken) {
        try {
            const response = await axios.get(`${baseURL}/api/csrf-token`, { withCredentials: true });
            if (response.data && response.data.token) {
                csrfToken = response.data.token;
            }
        } catch (error) {
            console.error('Failed to fetch CSRF token:', error);
            throw error;
        }
    }
    return csrfToken;
};

// Request interceptor
instance.interceptors.request.use(
    async config => {
        config.withCredentials = true;
        // Add CSRF token for non-GET requests
        if (['POST', 'PUT', 'DELETE'].includes(config.method?.toUpperCase())) {
            try {
                const token = await getCsrfToken();
                if (token) {
                    config.headers['X-CSRF-Token'] = token;
                }
            } catch (error) {
                console.error('Failed to set CSRF token:', error);
            }
        }

        // Comment out request logging
        /*
        console.log('Making request:', {
            method: config.method,
            url: config.url,
            headers: config.headers,
            baseURL: config.baseURL
        });
        */
        return config;
    },
    error => {
        // Keep error logging for debugging issues
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.error('Request error:', {
                message: error.message,
                status: error.response.status,
                data: error.response.data
            });
        } else if (error.request) {
            // The request was made but no response was received
            console.error('Request error:', error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.error('Error:', error.message);
        }
        return Promise.reject(error);
    }
);

// Response interceptor
instance.interceptors.response.use(
    response => {
        // Comment out success response logging
        /*
        console.log('Response received:', {
            status: response.status,
            data: response.data
        });
        */
        return response;
    },
    async error => {
        // Keep error logging for debugging issues
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.error('Response error:', {
                message: error.message,
                status: error.response.status,
                data: error.response.data
            });
        } else if (error.request) {
            // The request was made but no response was received
            console.error('Request error:', error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.error('Error:', error.message);
        }

        // Handle CSRF token errors
        if (error.response?.status === 403 && 
            error.response?.data?.error === 'Invalid CSRF token') {
            try {
                console.log('Invalid CSRF token, refreshing...');
                csrfToken = null; // Clear existing token
                const token = await getCsrfToken(); // Get new token
                
                // Retry the original request with new token
                const originalRequest = error.config;
                originalRequest.headers['X-CSRF-Token'] = token;
                return instance(originalRequest);
            } catch (retryError) {
                console.error('Failed to refresh CSRF token:', retryError);
                return Promise.reject(retryError);
            }
        }

        return Promise.reject(error);
    }
);

export default instance; 