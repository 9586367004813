import { useState, useCallback } from 'react';
import axios from '../utils/axiosConfig';

// Cache object to store poll data
const pollsCache = {
    data: new Map(),
    timestamp: new Map(),
    CACHE_DURATION: 30 * 1000, // 30 seconds
};

export const usePolls = () => {
    const [polls, setPolls] = useState([]);
    const [error, setError] = useState(null);

    const fetchPolls = useCallback(async (type = 'trending', categories = [], timeFilter = 'day') => {
        try {
            // Create cache key
            const cacheKey = `${type}-${categories.join(',')}-${timeFilter}`;
            const now = Date.now();
            
            // Check cache
            if (pollsCache.data.has(cacheKey)) {
                const cachedData = pollsCache.data.get(cacheKey);
                const cachedTime = pollsCache.timestamp.get(cacheKey);
                
                // If cache is still fresh, use it
                if (now - cachedTime < pollsCache.CACHE_DURATION) {
                    setPolls(cachedData);
                    setError(null);
                    
                    // Fetch in background to update cache
                    fetchAndUpdateCache(cacheKey, type, categories, timeFilter);
                    return;
                }
            }

            // If no cache or cache is stale, fetch from server
            await fetchAndUpdateCache(cacheKey, type, categories, timeFilter);
            
        } catch (err) {
            console.error('Error fetching polls:', err);
            setError('Failed to load polls');
        }
    }, []);

    // Helper function to fetch and update cache
    const fetchAndUpdateCache = async (cacheKey, type, categories, timeFilter) => {
        const response = await axios.get('/api/polls/list', { 
            params: { 
                type,
                categories: categories.join(','),
                timeFilter
            }
        });
        
        // Update data with isNew flag and ensure closed polls show results
        const updatedPolls = response.data.map(newPoll => ({
            ...newPoll,
            isNew: false,
            has_voted: newPoll.has_voted || newPoll.is_closed // Show results for closed polls
        }));
        
        // Update cache
        pollsCache.data.set(cacheKey, updatedPolls);
        pollsCache.timestamp.set(cacheKey, Date.now());
        
        // Update state
        setPolls(updatedPolls);
        setError(null);
    };

    // Clear cache when needed (e.g., after voting)
    const clearCache = useCallback((type, categories, timeFilter) => {
        const cacheKey = `${type}-${categories.join(',')}-${timeFilter}`;
        pollsCache.data.delete(cacheKey);
        pollsCache.timestamp.delete(cacheKey);
    }, []);

    return { polls, error, fetchPolls, clearCache };
}; 