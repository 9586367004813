import { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import axios from '../../utils/axiosConfig';
import '../../styles/Admin.css';

const ProtectedRoute = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(null);
    const location = useLocation();

    useEffect(() => {
        const verifyAuth = async () => {
            try {
                await axios.get('/api/admin/verify');
                setIsAuthenticated(true);
            } catch (err) {
                setIsAuthenticated(false);
            }
        };
        verifyAuth();
    }, []);

    if (isAuthenticated === null) {
        return <div className="admin-loading">Loading...</div>;
    }

    if (!isAuthenticated) {
        return <Navigate to="/admin/login" state={{ from: location }} replace />;
    }

    return children;
};

export default ProtectedRoute; 