import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { IoTriangle, IoCalendarOutline } from 'react-icons/io5';
import axios from '../utils/axiosConfig';
import PollCard from './PollCard';
import { useMetaTags } from '../hooks/useMetaTags';
import logo from '../assets/opini.io_night.png';
import '../styles/PollPage.css';
import { motion } from 'framer-motion';
import PollMap from './PollMap';

const PollPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { from, timeFilter, selectedCategories } = location.state || {};
    const [poll, setPoll] = useState(null);
    const [instances, setInstances] = useState([]);
    const [selectedInstance, setSelectedInstance] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { pollId } = useParams();

    useEffect(() => {
        const fetchPoll = async () => {
            try {
                const response = await axios.get(`/api/polls/single/${pollId}`);
                setPoll(response.data);
                
                // If this is a recurring poll, fetch all instances
                if (response.data.is_recurring || response.data.parent_poll_id) {
                    const parentId = response.data.parent_poll_id || response.data.id;
                    const instancesResponse = await axios.get(`/api/polls/instances/${parentId}`);
                    setInstances(instancesResponse.data);
                    
                    // Set the selected instance to the current poll
                    const currentInstance = instancesResponse.data.find(i => i.id === parseInt(pollId));
                    setSelectedInstance(currentInstance || response.data);
                }
            } catch (err) {
                setError('Failed to load poll');
                console.error('Error fetching poll:', err);
            } finally {
                setLoading(false);
            }
        };

        fetchPoll();
    }, [pollId]);

    // Set meta tags when poll data is available
    useMetaTags({
        title: poll ? `${poll.title}${poll.is_recurring ? ' (Recurring Poll)' : ''}` : 'Loading poll...',
        description: poll ? poll.options.map(o => o.option).join(' • ') : '',
        url: `${window.location.origin}/poll/${pollId}`,
        image: logo,
        type: 'poll'
    });

    const handleBack = () => {
        if (from) {
            navigate(from, {
                state: { 
                    timeFilter,
                    selectedCategories,
                    preserveScroll: true
                }
            });
        } else {
            navigate(-1);
        }
    };

    const handleInstanceSelect = (instance) => {
        setSelectedInstance(instance);
        // Update URL without reloading
        navigate(`/poll/${instance.id}`, { replace: true });
    };

    const renderContent = () => {
        if (loading) return <div className="loading">Loading...</div>;
        if (error) return <div className="error">{error}</div>;
        if (!poll) return <div className="error">Poll not found</div>;

        const displayPoll = selectedInstance || poll;
        const isRecurring = poll.is_recurring || poll.parent_poll_id;

        return (
            <>
                <motion.div 
                    className="poll-section"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0.2 }}
                >
                    {isRecurring && instances.length > 0 && (
                        <div className="instance-selector">
                            <div className="instance-header">
                                <IoCalendarOutline />
                                <span>Poll Instances</span>
                            </div>
                            <select 
                                value={displayPoll.id} 
                                onChange={(e) => {
                                    const instance = instances.find(i => i.id === parseInt(e.target.value));
                                    handleInstanceSelect(instance);
                                }}
                            >
                                {instances.map(instance => (
                                    <option key={instance.id} value={instance.id}>
                                        {new Date(instance.created_at).toLocaleDateString()} 
                                        {instance.is_latest_instance ? ' (Latest)' : ''}
                                        {instance.is_closed ? ' (Closed)' : ''}
                                    </option>
                                ))}
                            </select>
                            <div className="instance-info">
                                {displayPoll.is_closed ? 'This instance is closed' : 'This instance is active'}
                            </div>
                        </div>
                    )}
                    <PollCard 
                        poll={displayPoll}
                        showShareButton={true}
                        showNavBullet={false}
                    />
                </motion.div>
                <motion.div 
                    className="stats-section"
                    initial={{ opacity: 0, x: 20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ delay: 0.3 }}
                >
                    <h2>Vote Distribution</h2>
                    <PollMap pollId={displayPoll.id} />
                </motion.div>
            </>
        );
    }

    return (
        <div className="container">
            <motion.div 
                className="poll-page-header"
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.2 }}
            >
                <motion.button 
                    className="nav-bullet back"
                    onClick={handleBack}
                    whileHover={{ x: -4 }}
                >
                    <IoTriangle size={24} />
                </motion.button>
            </motion.div>
            <motion.div 
                className="poll-page-content"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.3 }}
            >
                {renderContent()}
            </motion.div>
        </div>
    );
}

export default PollPage; 