import React, { useState } from 'react';
import axios from '../utils/axiosConfig';
import './ReportModal.css';

const REPORT_REASONS = [
    'Hate Speech or Discrimination',
    'Inappropriate or Adult Content',
    'Harassment or Bullying',
    'Misinformation',
    'Spam or Misleading',
    'Violence or Gore',
    'Other'
];

const ReportModal = ({ pollId, onClose }) => {
    const [selectedReason, setSelectedReason] = useState('');
    const [details, setDetails] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!selectedReason) {
            setError('Please select a reason for reporting');
            return;
        }

        setIsSubmitting(true);
        setError('');

        try {
            await axios.post('/api/reports', {
                pollId,
                reason: selectedReason,
                details: details.trim() || null
            });

            setSuccess(true);
            setTimeout(() => {
                onClose();
            }, 2000);
        } catch (err) {
            if (err.response?.status === 429) {
                setError('You have reported too many polls recently. Please try again later.');
            } else if (err.response?.status === 400) {
                setError(err.response.data.error || 'Invalid report submission');
            } else {
                setError('Failed to submit report. Please try again.');
            }
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <>
            <div className="report-modal-backdrop" onClick={onClose} />
            <div className="report-modal">
                <div className="report-modal-content">
                    <h3>Report Poll</h3>
                    {success ? (
                        <div className="success-message">
                            Thank you for your report. We will review it shortly.
                        </div>
                    ) : (
                        <form onSubmit={handleSubmit}>
                            {error && <div className="error-message">{error}</div>}
                            <div className="form-group">
                                <label>Reason for reporting:</label>
                                <div className="report-options">
                                    {REPORT_REASONS.map((reason) => (
                                        <div 
                                            key={reason}
                                            className={`report-option ${selectedReason === reason ? 'selected' : ''}`}
                                            onClick={() => setSelectedReason(reason)}
                                        >
                                            {reason}
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="form-group">
                                <label>Additional details (optional):</label>
                                <textarea
                                    value={details}
                                    onChange={(e) => setDetails(e.target.value)}
                                    placeholder="Please provide any additional context..."
                                    maxLength={500}
                                />
                            </div>
                            <div className="modal-actions">
                                <button 
                                    type="button" 
                                    className="cancel-button"
                                    onClick={onClose}
                                    disabled={isSubmitting}
                                >
                                    Cancel
                                </button>
                                <button 
                                    type="submit"
                                    className="submit-button"
                                    disabled={!selectedReason || isSubmitting}
                                >
                                    {isSubmitting ? 'Submitting...' : 'Submit Report'}
                                </button>
                            </div>
                        </form>
                    )}
                </div>
            </div>
        </>
    );
};

export default ReportModal; 