import React, { useState, useEffect, useMemo, useRef } from 'react';
import { MdOutlineReport, MdOutlineComputer, MdOutlinePeople, 
    MdOutlineAttachMoney, MdOutlineAccountBalance, MdOutlineScience, 
    MdOutlineSports, MdOutlineMood } from 'react-icons/md';
import { IoShareOutline, IoCopy, IoCalendarOutline } from 'react-icons/io5';
import axios from '../utils/axiosConfig';
import { formatDistanceToNow } from 'date-fns';
import { useMetaTags } from '../hooks/useMetaTags';
import { 
    FacebookShareButton, 
    TwitterShareButton, 
    WhatsappShareButton,
    TelegramShareButton,
    RedditShareButton,
    FacebookIcon,
    WhatsappIcon,
    TelegramIcon,
    RedditIcon
} from 'react-share';
import { SiX, SiBluesky } from 'react-icons/si';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import logo from '../assets/opini.io_night.png';
import { usePollContext } from '../context/PollContext';
import ReportModal from './ReportModal';

const CATEGORY_COLORS = {
    'Tech': {
        primary: '#00BCD4',    // Cyan - representing technology and innovation
        darker: '#0097A7'
    },
    'Society': {
        primary: '#9C27B0',    // Purple - representing diversity and community
        darker: '#7B1FA2'
    },
    'Economy': {
        primary: '#4CAF50',    // Green - representing money and growth
        darker: '#388E3C'
    },
    'Politics': {
        primary: '#F44336',    // Red - traditional political color
        darker: '#D32F2F'
    },
    'Science': {
        primary: '#2196F3',    // Blue - representing knowledge and research
        darker: '#1976D2'
    },
    'Sports': {
        primary: '#FF9800',    // Orange - representing energy and activity
        darker: '#F57C00'
    },
    'Fun': {
        primary: '#E91E63',    // Pink - representing entertainment and joy
        darker: '#C2185B'
    },
    'default': {
        primary: '#757575',    // Grey - fallback for unknown categories
        darker: '#616161'
    }
};

const categoryIcons = {
    'Tech': <MdOutlineComputer />,
    'Society': <MdOutlinePeople />,
    'Economy': <MdOutlineAttachMoney />,
    'Politics': <MdOutlineAccountBalance />,
    'Science': <MdOutlineScience />,
    'Sports': <MdOutlineSports />,
    'Fun': <MdOutlineMood />
};

const PollCard = ({ poll, onDelete, showNavBullet = true, selectedCategories = [] }) => {
    if (!poll || !poll.title) {
        return null;
    }

    const { newPollId } = usePollContext();
    const [currentPoll, setCurrentPoll] = useState(poll);
    const [selectedOption, setSelectedOption] = useState(null);
    const [hasVoted, setHasVoted] = useState(poll?.has_voted || false);
    const [error, setError] = useState('');
    const [showShareMenu, setShowShareMenu] = useState(false);
    const [showReportModal, setShowReportModal] = useState(false);
    const [showCopiedTooltip, setShowCopiedTooltip] = useState(false);
    const [totalVotes, setTotalVotes] = useState(0);
    const navigate = useNavigate();
    const { markPollAsNew } = usePollContext();
    const shareMenuRef = useRef(null);

    // Update currentPoll when poll prop changes
    useEffect(() => {
        if (poll) {
            setCurrentPoll(poll);
        }
    }, [poll]);

    useMetaTags({
        title: currentPoll?.title || '',
        description: currentPoll?.options?.map(o => o.option).join(' • ') || '',
        url: currentPoll ? `${window.location.origin}/poll/${currentPoll.id}` : '',
        image: logo,
        type: 'poll'
    });

    useEffect(() => {
        setHasVoted(currentPoll?.has_voted || false);
    }, [currentPoll?.has_voted]);

    useEffect(() => {
        if (!currentPoll?.options) return;
        const total = currentPoll.options.reduce((sum, opt) => sum + (opt.votes || 0), 0);
        setTotalVotes(total);
    }, [currentPoll?.options]);
    
    const pollColor = useMemo(() => {
        const categoryColor = CATEGORY_COLORS[currentPoll?.category] || CATEGORY_COLORS.default;
        return categoryColor.primary;
    }, [currentPoll?.category]);

    const darkerPollColor = useMemo(() => {
        const categoryColor = CATEGORY_COLORS[currentPoll?.category] || CATEGORY_COLORS.default;
        return categoryColor.darker;
    }, [currentPoll?.category]);

    const sortedOptions = useMemo(() => {
        if (!currentPoll?.options) return [];
        return [...currentPoll.options].sort((a, b) => {
            const aPercentage = totalVotes ? (a.votes / totalVotes) * 100 : 0;
            const bPercentage = totalVotes ? (b.votes / totalVotes) * 100 : 0;
            return bPercentage - aPercentage;
        });
    }, [currentPoll?.options, totalVotes]);

    const [timeAgo, setTimeAgo] = useState('');

    useEffect(() => {
        if (currentPoll?.created_at) {
            const updateTimeAgo = () => {
                const timeString = formatDistanceToNow(new Date(currentPoll.created_at))
                    .replace('less than a', '')
                    .replace(' seconds', ' secs')
                    .replace(' minutes', ' mins')
                    .replace(' minute', ' min')
                    .replace(' hours', ' hrs')
                    .replace(' hour', ' hr')
                    .replace('about ', '')
                setTimeAgo(timeString);
            };
            
            updateTimeAgo();
            const interval = setInterval(updateTimeAgo, 60000);
            
            return () => clearInterval(interval);
        }
    }, [currentPoll?.created_at]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!selectedOption) {
            alert('Please select an option');
            return;
        }

        try {
            // Only get a new CSRF token if we don't have one
            const token = document.cookie.split('; ')
                .find(row => row.startsWith('_csrf='))
                ?.split('=')[1];
                
            if (!token) {
                await axios.get('/api/csrf-token');
            }
            
            const response = await axios.post(`/api/vote/${currentPoll.id}`, {
                option: selectedOption
            });
            
            setHasVoted(true);
            setCurrentPoll(prevPoll => ({
                ...prevPoll,
                options: response.data.options,
                total_votes: response.data.total_votes
            }));
            
        } catch (error) {
            if (error.response?.status === 403 && error.response?.data?.message === 'Already voted') {
                setHasVoted(true);
            } else if (error.response?.status === 429) {
                // Handle rate limit error
                const retryAfter = error.response.data.retry_after || 60;
                setError(`Too many requests. Please wait ${retryAfter} seconds before trying again.`);
            } else {
                console.error('Vote error:', error);
                setError(error.response?.data?.error || 'Error submitting vote');
            }
        }
    };

    const getCategoryIcon = (category) => {
        const normalizedCategory = category?.trim();
        if (!normalizedCategory) return categoryIcons['Tech'];

        // Find matching category ignoring case
        const match = Object.keys(categoryIcons).find(
            key => key.toLowerCase() === normalizedCategory.toLowerCase()
        );
        
        return categoryIcons[match] || categoryIcons['Tech'];
    };

    const handleShare = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (!currentPoll) return;

        const shareUrl = `${window.location.origin}/poll/${currentPoll.id}`;
        const shareText = `Vote on this poll: ${currentPoll.title}`;

        if (typeof navigator.share !== 'function') {
            // Fallback to share menu
            setShowShareMenu(!showShareMenu);
            return;
        }

        try {
            await navigator.share({
                text: shareText,
                url: shareUrl,
            });
        } catch (err) {
            if (err.name !== 'AbortError') {
                console.error('Error sharing:', err);
                // Fallback to share menu
                setShowShareMenu(!showShareMenu);
            } else {
                console.log('Share aborted by user.');
            }
        }
    };

    const ShareMenu = ({ url, title, onClose }) => {
        const [isVisible, setIsVisible] = useState(false);
        
        useEffect(() => {
            setIsVisible(true);
            return () => setIsVisible(false);
        }, []);

        if (!currentPoll) return null;
        if (!isVisible) return null;
        
        const shareTitle = `Give your opinion! - ${currentPoll.title}`;
        
        const handleCopyLink = async () => {
            try {
                await navigator.clipboard.writeText(url);
                const copyButton = document.querySelector('.copy-link-button');
                copyButton.classList.add('copied');
                setTimeout(() => copyButton.classList.remove('copied'), 1500);
            } catch (err) {
                console.error('Failed to copy:', err);
            }
        };

        const handleBlueSkyShare = () => {
            const blueskyUrl = `https://bsky.app/intent/compose?text=${encodeURIComponent(shareTitle + '\n' + url)}`;
            window.open(blueskyUrl, '_blank');
        };
        
        return (
            <>
                <div className="share-menu-backdrop" onClick={onClose} />
                <div className="share-menu">
                    <div className="share-menu-content">
                        <button onClick={handleBlueSkyShare} className="bluesky-button" title="Share on BlueSky">
                            <div className="bluesky-icon-wrapper">
                                <SiBluesky size={20} />
                            </div>
                        </button>

                        <TwitterShareButton url={url} title={shareTitle}>
                            <div className="x-icon-wrapper">
                                <SiX size={20} />
                            </div>
                        </TwitterShareButton>

                        <RedditShareButton url={url} title={shareTitle}>
                            <RedditIcon size={28} round bgStyle={{ fill: 'transparent' }} iconFillColor="white" />
                        </RedditShareButton>

                        <WhatsappShareButton url={url} title={shareTitle}>
                            <WhatsappIcon size={28} round bgStyle={{ fill: 'transparent' }} iconFillColor="white" />
                        </WhatsappShareButton>

                        <FacebookShareButton url={url} quote={shareTitle}>
                            <FacebookIcon size={28} round bgStyle={{ fill: 'transparent' }} iconFillColor="white" />
                        </FacebookShareButton>

                        <TelegramShareButton url={url} title={shareTitle}>
                            <TelegramIcon size={28} round bgStyle={{ fill: 'transparent' }} iconFillColor="white" />
                        </TelegramShareButton>

                        <button onClick={handleCopyLink} className="copy-link-button">
                            <IoCopy size={20} />
                        </button>
                    </div>
                </div>
            </>
        );
    };

    const handleTitleClick = (e) => {
        if (!currentPoll) return;
        
        e.preventDefault();
        e.stopPropagation();
        // Extract just the timeFilter value from the URL
        const params = new URLSearchParams(window.location.search);
        const currentTimeFilter = params.get('timeFilter') || 'day';
        
        navigate(`/poll/${currentPoll.id}`, {
            state: {
                from: window.location.pathname,
                timeFilter: currentTimeFilter,
                selectedCategories
            }
        });
    };

    const isHighlighted = currentPoll?.id === newPollId;

    const handleReport = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setShowReportModal(true);
    };

    if (!currentPoll) {
        return null;
    }

    return (
        <motion.div 
            layoutId={`poll-${currentPoll.id}`}
            className={`poll ${isHighlighted ? 'new-poll' : ''} ${currentPoll.is_closed ? 'closed-poll' : ''}`}
            data-highlighted={isHighlighted}
            data-category={currentPoll.category?.toLowerCase()}
        >
            {currentPoll.is_closed && <div className="poll-closed-ribbon">Closed</div>}
            <motion.div className="poll-header" layoutId={`header-${currentPoll.id}`}>
                <motion.div className="poll-title-area">
                    <motion.div className="poll-meta" layoutId={`meta-${currentPoll.id}`}>
                        <motion.div className="poll-category" layoutId={`category-${currentPoll.id}`}>
                            {getCategoryIcon(currentPoll.category)}
                            <span>{currentPoll.category}</span>
                        </motion.div>
                        {currentPoll.is_recurring ? (
                            <motion.div 
                                className="recurring-indicator" 
                                layoutId={`recurring-${currentPoll.id}`}
                                title="This poll repeats regularly"
                            >
                                <span>Recurring</span>
                                <IoCalendarOutline />
                            </motion.div>
                        ) : (
                            <motion.button 
                                className="report-button" 
                                layoutId={`report-${currentPoll.id}`}
                                onClick={handleReport}
                            >
                                <span>Report</span>
                                <MdOutlineReport />
                            </motion.button>
                        )}
                    </motion.div>
                    <motion.div className="title-with-nav" layoutId={`title-area-${currentPoll.id}`}>
                        <motion.h3 
                            layoutId={`title-${currentPoll.id}`}
                            style={{'--char-count': currentPoll.title.length}}
                            onClick={showNavBullet ? handleTitleClick : undefined}
                            className={showNavBullet ? 'clickable-title' : ''}
                        >
                            {currentPoll.title}
                        </motion.h3>
                    </motion.div>
                </motion.div>
            </motion.div>

            {error && <div className="error">{error}</div>}
            
            <motion.div layoutId={`content-${currentPoll.id}`} className="poll-content">
                {!hasVoted && !currentPoll.is_closed ? (
                    <div className="options-container" style={{'--option-count': currentPoll.options?.length || 0}}>
                        {(currentPoll.options || []).map((option) => (
                            <div 
                                key={`vote-${currentPoll.id}-${option.id}`}
                                className={`option-button ${selectedOption === option.option ? 'active' : ''}`}
                                onClick={() => setSelectedOption(selectedOption === option.option ? null : option.option)}
                            >
                                {option.option}
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className="results" style={{'--option-count': currentPoll.options?.length || 0}}>
                        {sortedOptions.map(option => (
                            <div 
                                className="result-bar" 
                                key={`result-${currentPoll.id}-${option.id}`}
                            >
                                <div 
                                    className="result-label"
                                    style={{'--char-count': option.option?.length || 0}}
                                >
                                    {option.option}
                                </div>
                                <div className="bar-background">
                                    <div 
                                        className="bar"
                                        style={{
                                            width: `${totalVotes ? (option.votes / totalVotes) * 100 : 0}%`,
                                            '--bar-color': pollColor,
                                            '--darker-bar-color': darkerPollColor
                                        }}
                                    />
                                    <span className="percentage">
                                        {totalVotes ? Math.round((option.votes / totalVotes) * 100) : 0}%
                                    </span>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </motion.div>

            <motion.div 
                className={`poll-footer ${hasVoted ? 'has-voted' : ''}`} 
                layoutId={`footer-${currentPoll.id}`}
            >
                <div className="footer-left">
                    <button 
                        className="share-button"
                        onClick={handleShare}
                        title="Share poll"
                    >
                        <IoShareOutline />
                    </button>
                    {showShareMenu && (
                        <ShareMenu 
                            url={`${window.location.origin}/poll/${currentPoll.id}`}
                            title={`Vote on: ${currentPoll.title}`}
                            onClose={() => setShowShareMenu(false)}
                        />
                    )}
                </div>
                {hasVoted || currentPoll.is_closed ? (
                    <div className="total-votes">
                        {totalVotes} {totalVotes === 1 ? 'vote' : 'votes'}
                        {timeAgo && ` • ${timeAgo}`}
                    </div>
                ) : (
                    <button 
                        className={`vote-button-footer ${selectedOption ? '' : 'disabled'}`}
                        onClick={handleSubmit}
                        disabled={!selectedOption}
                    >
                        Vote
                    </button>
                )}
            </motion.div>

            {showReportModal && (
                <ReportModal 
                    pollId={currentPoll.id}
                    onClose={() => setShowReportModal(false)}
                />
            )}
        </motion.div>
    );
};

export default PollCard;