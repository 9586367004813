import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { IoShieldCheckmarkOutline, IoTimeOutline, IoLockClosedOutline, IoFingerPrintOutline } from 'react-icons/io5';
import '../styles/PrivacyPolicy.css';

const PrivacyPolicy = () => {
  const [showEmail, setShowEmail] = useState(false);
  
  const handleEmailClick = () => {
    setShowEmail(true);
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2
      }
    }
  };

  const sectionVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5
      }
    }
  };

  return (
    <motion.div 
      className="privacy-container"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
      <div className="privacy-sections">
        <motion.div className="privacy-section" variants={sectionVariants}>
          <div className="section-icon">
            <IoShieldCheckmarkOutline />
          </div>
          <div className="section-content">
            <h2>Minimal Data Collection</h2>
            <p>
              We keep things simple! We don't track you, create user accounts,
              or store personal data. Just pure, anonymous polling goodness.
            </p>
          </div>
        </motion.div>

        <motion.div className="privacy-section" variants={sectionVariants}>
          <div className="section-icon">
            <IoFingerPrintOutline />
          </div>
          <div className="section-content">
            <h2>What We Store</h2>
            <p>
              To maintain vote integrity and enable cross-poll analysis, we store hashed IP addresses 
              (never the actual IPs!) and session tokens. These anonymized records help us understand 
              voting patterns while ensuring fair poll results.
            </p>
          </div>
        </motion.div>

        <motion.div className="privacy-section" variants={sectionVariants}>
          <div className="section-icon">
            <IoTimeOutline />
          </div>
          <div className="section-content">
            <h2>Data Retention</h2>
            <p>
              Session tokens expire after 24 hours. Anonymized vote records are retained for 
              cross-poll analysis and research purposes, helping us understand opinion trends 
              across different polls over time. These records cannot be traced back to individual users.
            </p>
          </div>
        </motion.div>

        <motion.div className="privacy-section" variants={sectionVariants}>
          <div className="section-icon">
            <IoLockClosedOutline />
          </div>
          <div className="section-content">
            <h2>You're in Control</h2>
            <p>
              No cookies to manage, no tracking to opt out of, no personal data to worry about.
              We believe in keeping things simple and respecting your privacy by default.
            </p>
          </div>
        </motion.div>
      </div>

      <motion.div className="privacy-footer" variants={sectionVariants}>
        <p>
          Questions? {!showEmail ? (
            <button onClick={handleEmailClick} className="reveal-email-btn">
              Click to reveal contact email
            </button>
          ) : (
            <a href="mailto:contact@opini.io" className="email-link">
              contact@opini.io
            </a>
          )}
        </p>
      </motion.div>
    </motion.div>
  );
};

export default PrivacyPolicy; 