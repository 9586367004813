import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from '../../utils/axiosConfig';
import '../../styles/Admin.css';

const AdminLogin = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const location = useLocation();

    // Check if already authenticated
    useEffect(() => {
        const checkAuth = async () => {
            try {
                const response = await axios.get('/api/admin/verify');
                if (response.data.authenticated) {
                    navigate('/admin/dashboard');
                }
            } catch (err) {
                // Not authenticated, stay on login page
                console.log('Not authenticated:', err);
            }
        };
        checkAuth();
    }, [navigate]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');

        try {
            await axios.post('/api/admin/login', { 
                username, 
                password 
            });
            
            // Verify authentication after login
            const verifyResponse = await axios.get('/api/admin/verify');
            if (verifyResponse.data.authenticated) {
                // Redirect to dashboard or previous attempted location
                const from = location.state?.from?.pathname || '/admin/dashboard';
                navigate(from, { replace: true });
            } else {
                setError('Authentication failed');
            }
        } catch (err) {
            console.error('Login error:', err);
            setError(err.response?.data?.error || 'Login failed');
        }
    };

    return (
        <div className="admin-login-container">
            <div className="admin-login-box">
                <h2>Admin Login</h2>
                {error && <div className="admin-error">{error}</div>}
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <input
                            type="text"
                            placeholder="Username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <input
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </div>
                    <button 
                        type="submit" 
                        className="admin-login-button"
                    >
                        Login
                    </button>
                </form>
            </div>
        </div>
    );
};

export default AdminLogin;