import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../utils/axiosConfig';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../styles/CreatePoll.css';
import { usePollContext } from '../context/PollContext';

const CreatePoll = () => {
    const { markPollAsNew } = usePollContext();
    const [title, setTitle] = useState('');
    const [category, setCategory] = useState('Tech');
    const [options, setOptions] = useState(['', '']);
    const [error, setError] = useState('');
    const [canCreate, setCanCreate] = useState(true);
    const [remainingPolls, setRemainingPolls] = useState(2);

    const navigate = useNavigate();

    const CHARACTER_LIMIT = 90;

    useEffect(() => {
        const checkPollLimit = async () => {
            setError(''); // Clear any existing error
            try {
                const response = await axios.get('/api/check-poll-limit');
                console.log('Poll limit response:', response.data); // Debug log
                
                setCanCreate(response.data.canCreate);
                setRemainingPolls(response.data.remaining);
                
                // Only show error if actually reached limit
                if (response.data.remaining === 0) {
                    setError('Daily poll limit reached (2 polls per day)');
                }
            } catch (error) {
                console.error('Error checking poll limit:', error.response?.data || error);
                setError(error.response?.data?.message || 'Error checking poll limit');
            }
        };
        checkPollLimit();
    }, [navigate]);

    const handleTitleChange = (e) => {
        const newTitle = e.target.value;
        if (newTitle.length <= CHARACTER_LIMIT) {
            setTitle(newTitle);
        }
    };

    const addOption = () => {
        if (options.length < 6) {
            setOptions([...options, '']);
        }
    };

    const removeOption = (index) => {
        if (options.length > 2) {
            const newOptions = options.filter((_, i) => i !== index);
            setOptions(newOptions);
        }
    };

    const handleOptionChange = (index, value) => {
        const newOptions = [...options];
        newOptions[index] = value;
        setOptions(newOptions);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');

        // Validate inputs
        if (!title.trim()) {
            setError('Please enter a poll title');
            return;
        }

        const validOptions = options.filter(opt => opt.trim());
        if (validOptions.length < 2) {
            setError('Please enter at least 2 options');
            return;
        }

        // Check for duplicate options (case-insensitive and trimmed)
        const normalizedOptions = validOptions.map(opt => opt.trim().toLowerCase());
        const uniqueOptions = new Set(normalizedOptions);
        if (uniqueOptions.size !== normalizedOptions.length) {
            setError('Duplicate options are not allowed');
            return;
        }

        try {
            console.log('Submitting poll:', { title, category, options: validOptions });
            
            const response = await axios.post('/api/create-poll', { 
                title, 
                category, 
                options: validOptions 
            });

            console.log('Poll creation response:', response.data);

            // Update remaining polls count after successful creation
            const limitResponse = await axios.get('/api/check-poll-limit');
            setRemainingPolls(limitResponse.data.remaining);
            setCanCreate(limitResponse.data.canCreate);

            if (response.data.notice) {
                toast.info(response.data.notice, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }

            if (response.data.success) {
                markPollAsNew(response.data.poll_id);
                navigate('/new');
            }
        } catch (err) {
            console.error('Poll creation error details:', {
                status: err.response?.status,
                data: err.response?.data,
                headers: err.response?.headers,
                config: err.config
            });

            if (err.response?.status === 429) {
                setError(err.response.data.message || 'Daily poll limit reached');
            } else if (err.response?.status === 400 && err.response.data.error === 'Duplicate options') {
                setError('Duplicate options are not allowed');
            } else {
                setError('Failed to create poll. Please try again.');
            }
        }
    };

    return (
        <div className="create-poll-container">
            <h1>New poll</h1>
            {error && remainingPolls === 0 ? (
                <div className="error-message">
                    You have reached your daily limit of 2 polls. Please try again tomorrow.
                </div>
            ) : (
                <>
                    {remainingPolls !== null && (
                        <div className="polls-remaining">
                            Polls remaining today: {remainingPolls}
                        </div>
                    )}
                    <form className="create-poll-form" onSubmit={handleSubmit}>
                        {error && error !== 'Daily poll limit reached (2 polls per day)' && (
                            <div className="error-message">{error}</div>
                        )}
                        <div className="form-group">
                            <label htmlFor="category">Category</label>
                            <select 
                                id="category" 
                                name="category" 
                                value={category} 
                                onChange={(e) => setCategory(e.target.value)} 
                                required
                            >
                                <option value="Tech">Tech</option>
                                <option value="Society">Society</option>
                                <option value="Economy">Economy</option>
                                <option value="Politics">Politics</option>
                                <option value="Science">Science</option>
                                <option value="Sports">Sports</option>
                                <option value="Fun">Fun</option>
                            </select>
                            <span className="form-hint">Choose the most relevant category</span>
                        </div>
                        
                        <div className="form-group">
                            <label htmlFor="title">Question to answer</label>
                            <input 
                                type="text" 
                                id="title" 
                                name="title" 
                                value={title} 
                                onChange={handleTitleChange}
                                maxLength={CHARACTER_LIMIT}
                                required 
                            />
                            <span className="form-hint">
                                {CHARACTER_LIMIT - title.length} characters remaining (max 90)
                            </span>
                        </div>
                        
                        <div className="form-group">
                            <label>Select options</label>
                            {options.map((option, index) => (
                                <div key={index} className="option-input-group">
                                    <input
                                        type="text"
                                        value={option}
                                        onChange={(e) => {
                                            const newValue = e.target.value;
                                            if (newValue.length <= 45) {
                                                handleOptionChange(index, newValue);
                                            }
                                        }}
                                        maxLength={45}
                                        placeholder={`Option ${index + 1}`}
                                        required
                                    />
                                    <span className={`option-char-counter ${option.length >= 45 ? 'limit-reached' : ''}`}>
                                        {45 - option.length}
                                    </span>
                                    {options.length > 2 && (
                                        <button 
                                            type="button" 
                                            onClick={() => removeOption(index)}
                                            className="remove-option"
                                        >
                                            ×
                                        </button>
                                    )}
                                </div>
                            ))}
                            {options.length < 6 && (
                                <button 
                                    type="button" 
                                    onClick={addOption}
                                    className="add-option"
                                >
                                    + Add an option
                                </button>
                            )}
                        </div>
                        
                        <button 
                            type="submit" 
                            disabled={!canCreate}
                        >
                            Launch for opini-ions
                        </button>
                    </form>
                </>
            )}
        </div>
    );
};

export default CreatePoll;