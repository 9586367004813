import React, { useState } from 'react';
import { formatDistanceToNow } from 'date-fns';
import '../../styles/Admin.css';

const PendingPoll = ({ poll, onReview, isReported = false }) => {
    const [notes, setNotes] = useState('');
    const [banIp, setBanIp] = useState(false);

    const handleReview = (action) => {
        onReview(poll.id, action, notes, banIp);
    };

    return (
        <div className="pending-poll">
            {/* Section 1: Poll Title and Meta */}
            <div className="poll-header-admin">
                <div className="poll-info">
                    <span className="poll-category-admin">{poll.category}</span>
                    <span>{formatDistanceToNow(new Date(poll.created_at), { addSuffix: true })}</span>
                    {isReported ? (
                        <div className="flag-indicators">
                            <span className="banned-flag" title={`${poll.report_count} reports`}>
                                {poll.report_count}
                            </span>
                        </div>
                    ) : (
                        (poll.ai_flagged || poll.banned_word_flag) && (
                            <div className="flag-indicators">
                                {poll.ai_flagged && (
                                    <span className="ai-flag" title={poll.ai_flagged}>AI</span>
                                )}
                                {poll.banned_word_flag && (
                                    <span className="banned-flag">Word</span>
                                )}
                            </div>
                        )
                    )}
                </div>
                <h3 className="poll-title">{poll.title}</h3>
            </div>

            {/* Section 2: Options List */}
            <div className="poll-options">
                {poll.options.map((option, index) => (
                    <div key={index} className="option-admin">
                        <span>{option.option}</span>
                        {!isReported && (option.ai_flagged || option.banned_word_flag) && (
                            <div className="option-flags">
                                {option.ai_flagged && (
                                    <span className="ai-flag" title={option.ai_flagged}>AI</span>
                                )}
                                {option.banned_word_flag && (
                                    <span className="banned-flag">Word</span>
                                )}
                            </div>
                        )}
                    </div>
                ))}
            </div>

            {/* Section 3: Review Notes */}
            <div className="review-notes">
                <textarea
                    placeholder="Add review notes..."
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                />
            </div>

            {/* Section 4: Actions */}
            <div className="review-actions">
                <label className="ban-ip-label">
                    <input
                        type="checkbox"
                        checked={banIp}
                        onChange={(e) => setBanIp(e.checked)}
                    />
                    Ban IP (30 days)
                </label>
                <div className="action-buttons">
                    <button 
                        className="approve-button"
                        onClick={() => handleReview('approved')}
                    >
                        Approve
                    </button>
                    <button 
                        className="reject-button"
                        onClick={() => handleReview('rejected')}
                    >
                        Reject
                    </button>
                </div>
            </div>
        </div>
    );
};

export default PendingPoll; 