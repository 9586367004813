import React, { createContext, useContext, useState } from 'react';

const AnimationContext = createContext();

export const AnimationProvider = ({ children }) => {
    const [animatingPollId, setAnimatingPollId] = useState(null);
    const [sourceRect, setSourceRect] = useState(null);

    return (
        <AnimationContext.Provider value={{ 
            animatingPollId, 
            setAnimatingPollId,
            sourceRect,
            setSourceRect
        }}>
            {children}
        </AnimationContext.Provider>
    );
};

export const useAnimation = () => useContext(AnimationContext); 